/**
 * Represents a driver for managing device orientation events.
 */
export class DeviceOrientationDriver {
  lastOrientation = {alpha: 0, beta: 0, gamma: 0};

  /** @type {?HTMLElement} */
  debugStringElement = null;

  debugLog(str) {
    console.error(str);
  }

  init() {
    this.debugStringElement = document.getElementById('debug');

    this.debugStringElement.onclick = () => {
      this.requestPermission();
    }

    const isEnabledValue = window.localStorage.getItem('orientationDriver.isEnabled');
    if (isEnabledValue) {
      this.#isEnabled = JSON.parse(isEnabledValue);
    }

    // Check if the device supports device orientation events.
    if (window.DeviceOrientationEvent) {
      if (this.#isEnabled === true) {
        window.ondeviceorientation = this.handleOrientation.bind(this);
      } else {
        window.ondeviceorientation = null;
      }
    }
  }

  initOrientation() {
    // Check if the device supports device orientation events.
    if (window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', this.handleOrientation.bind(this));
    }
  }

  requestPermission() {
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            this.initOrientation();
          }
        })
        .catch((err) => {
          console.error(`Error requesting device orientation permission: ${err}`);
        });
    } else {
      this.initOrientation();
    }
  }

  handleOrientation(event) {
    const maxTilt = 90;
    const tiltX = Math.max(-maxTilt, Math.min(maxTilt, event.gamma));
    const tiltY = Math.max(-maxTilt, Math.min(maxTilt, event.beta));

    const positionX = (50 + (tiltX / maxTilt) * 50) + '%';
    const positionY = (50 + (tiltY / maxTilt) * 50) + '%';

    document.body.style.backgroundPositionX = positionX;
    document.body.style.backgroundPositionY = positionY;

    this.lastOrientation = {alpha: event.alpha, beta: event.beta, gamma: event.gamma};
  }

  toggleParallax() {
    this.#isEnabled = !this.#isEnabled;

    if (this.#isEnabled) {
      window.ondeviceorientation = null
      window.ondeviceorientation = this.handleOrientation.bind(this);
    } else {
      window.ondeviceorientation = null
    }

    // Update persistent setting.
    window.localStorage.setItem('orientationDriver.isEnabled', JSON.stringify(this.#isEnabled));
  }

  isEnabled() {
    return this.#isEnabled;
  }

  // --- Properties ---
  #isEnabled = false;
}
