import {SentryClient} from "./sentry-driver.js";
import * as Sentry from "@sentry/browser";
import {MessageTypes} from "./backend-driver.js";

/**
 * Class representing the game UI.
 */
export class GameUI {
  /** @type {?Sentry.Span} */
  #sentryPageLoadSpan = null;

//region Drivers
  /** @type {?Client} */
  #sentryClient = null;
  /** @type {?AudioDriver} */
  #audioDriver = null;
  /** @type {?HapticsDriver} */
  #hapticsDriver = null;
  /** @type {?BackendDriver} */
  #backendDriver = null;
  /** @type {?DeviceOrientationDriver} */
  #orientationDriver = null;
  /** @type {?I18nDriver} */
  #i18nDriver = null;
  /** @type {?FxDriver} */
  #fxDriver = null;
  /** @type {NodeListOf<HTMLElement>}}
   * Screens in the game. */
  #screens = null;

//endregion

//region Telegram Web App
  /** @type {NodeListOf<HTMLElement>}
   * Navigation buttons to switch between screens. */
  #navButtons = null;

//endregion

//region Navigation
  /** @type {?HTMLElement} */
  #userNameElement = null;
  /** @type {?HTMLElement} */
  #userBalanceElement = null;
  /** @type {?HTMLElement} */
  #userEnergyValueElement = null;
  /** @type {?HTMLElement} */
  #userEnergyMaxElement = null;
  /** @type {?HTMLElement} */
  #userEnergyBarElement = null;

//endregion
  /** @type {?HTMLElement} */
  #userEnergyBarFillElement = null;
  /** @type {?HTMLElement}
   * Spinner element. */
  #spinner = null;
  /** @type {number}
   * Opacity level for the spinner. */
  #spinnerOpacity = 1;
  /** @type {number}
   * Opacity level for the spinner when energy is empty. */
  #spinnerOpacityEmpty = 0.75;
//endregion

//region Main Screen
  /** @type {number}
   * Saturation level for the spinner. */
  #spinnerSaturation = 100;
  /** @type {number}
   * Saturation level for the spinner when energy is empty. */
  #spinnerSaturationEmpty = 25;
  /** @type {boolean}
   * Flag indicating whether the daily booster is active. */
  #boosterActive = false;
  /** @type {?HTMLElement}
   * Button opening share dialog to invite friends. */
  #inviteButton = null;
  #dailyItemList = [{
    id: 1,
    title: "daily-boost-turbo",
    icon: "🚀",
	description: "",
    available: 3,
    max: 3,
  }, {
    id: 2,
    title: "daily-boost-recharge",
    icon: "⚡",
	description: "",
    available: 3,
    max: 3,
  }];
  #upgradeItemList = [{
    id: 1,
    title: "upgrade-multiplier",
    icon: "💪",
	description: "",
    value: 1000,
    level: 0,
  }, {
    id: 2,
    title: "upgrade-recharge-speed",
    icon: "⚡",
	description: "",
    value: 1000,
    level: 0,
  }, {
    id: 3,
    title: "upgrade-max-energy",
    icon: "🔋",
	description: "",
    value: 1000,
    level: 0,
  }];
  /** @type {?HTMLElement}
   * Container for purchasable upgrade items. */
  #storeUpgradeContainerElement = null;
  /** @type {?HTMLTemplateElement}
   * Template for purchasable upgrade items. */
  #storeUpgradeItemTemplate = null;
  /** @type {?HTMLElement}
   * Container for daily boost items. */
  #storeDailyBoostContainerElement = null;
  /** @type {?HTMLTemplateElement}
   * Template for daily boost items. */
  #storeDailyBoostItemTemplate = null;
  /** @type {?HTMLElement}
   * Confirmation button for store screen. */
  #storeConfirmButton = null;
  /** @type {?HTMLElement}
   * Cancel button for store screen. */
  #storeCancelButton = null;
  /** @type {HTMLCollectionOf<Element>}
   * Clickable store items that summon the confirmation dialog. */
  #storeItemElements = [];
  /** @type {?HTMLElement}
   * Store confirmation dialog. */
  #storeConfirmation = null;
  /** @type {boolean}
   * Flag indicating whether the store confirmation dialog is transitioning. */
  #storeConfirmationTransitioning = null;
  /** @type {number}
   * Delay for store confirmation dialog transition to block multiple clicks. */
  #storeConfirmationTransitionDelay = 333;
  /** @type {?HTMLElement}
   * Selected item icon for store confirmation. */
  #storeConfirmationItemIcon = null;

//endregion

//region Friends Screen
  /** @type {?HTMLElement}
   * Selected item title for store confirmation. */
  #storeConfirmationItemTitle = null;
  /** @type {?HTMLElement}
   * Selected item value for store confirmation. */
  #storeConfirmationItemValue = null;
  /** @type {?HTMLElement}
   * Selected item level for store confirmation. */
  #storeConfirmationCoin = null;

//endregion

//region Leaderboard Screen
  /** @type {?HTMLElement}
   * Selected item level for store confirmation. */
  #storeConfirmationItemLevel = null;

//endregion

//region Store Screen
  /** @type {?HTMLElement}
   * Selected item level for store confirmation. */
  #storeConfirmationItemSeparator = null;
  /** @type {?HTMLElement}
   * Selected item level for store confirmation. */
  #storeConfirmationItemLevelLabel = null;
  /** @type {?HTMLElement}
   * Selected item description for store confirmation. */
  #storeConfirmationItemDescription = null;
  /** @type {?HTMLElement}
   * Audio control switch. */
  #audioControl = null;
  /** @type {?HTMLElement}
   * Haptics control switch. */
  #hapticsControl = null;
  /** @type {?HTMLElement}
   * Parallax control switch. */
  #parallaxControl = null;

  playerItems = [];
  allItems = [];
  quests = new Map();

  activeSpinnerSkin = null;
  activeBackground = null;
  //endregion
  /**
   * Initializes the settings for audio and haptics drivers and updates the relevant UI elements.
   *
   * @param {Client} sentryClient
   * @param {AudioDriver} audioDriver - The driver responsible for handling audio functionalities.
   * @param {HapticsDriver} hapticsDriver - The driver responsible for handling haptics functionalities.
   * @param {BackendDriver} backendDriver - The driver responsible for handling backend functionalities.
   * @param {DeviceOrientationDriver} orientationDriver - The driver responsible for handling device orientation functionalities.
   * @param {I18nDriver} i18nDriver - The driver responsible for handling internationalization functionalities.
   * @param {FxDriver} fxDriver - The driver responsible for handling special effects.
   */
  async init(sentryClient = null, audioDriver = null, hapticsDriver = null, backendDriver = null, orientationDriver = null, i18nDriver = null, fxDriver = null) {
    this.#sentryClient = sentryClient;
    this.#audioDriver = audioDriver;
    this.#hapticsDriver = hapticsDriver;
    this.#backendDriver = backendDriver;
    this.#orientationDriver = orientationDriver;
    this.#i18nDriver = i18nDriver;
    this.#fxDriver = fxDriver;

    this.#sentryPageLoadSpan = Sentry.startBrowserTracingPageLoadSpan(SentryClient, {
      name: "main-screen",
      attributes: {
        [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "url",
      }
    });

	// Set the callback for showing messages.
	this.#backendDriver.setMessagesCallback(this.handleShowMessage.bind(this));

    this.#initTelegramWebAppUI();
    this.#initNavigation();

    await this.#initMainScreen();
    await this.#initStoreScreen();
    await this.#initInviteButton();
	await this.#initItemShowcase();
    this.#initOptionsScreen();

	this.quests = await this.#backendDriver.getQuests();
  }

  #initTelegramWebAppUI() {
    if (window.Telegram && window.Telegram.WebApp) {
      if (typeof window.Telegram.WebApp.expand === "function") {
        if (!window.Telegram.WebApp.isExpanded) {
          window.Telegram.WebApp.expand();
        }

        if (typeof window.Telegram.WebApp.disableVerticalSwipes === "function") {
          window.Telegram.WebApp.disableVerticalSwipes();
        }
      }
    }
  }

  #initNavigation() {
    this.#navButtons = document.querySelectorAll("footer button");
    this.#screens = document.querySelectorAll("main .screen");

    // Set up event listeners for navigation buttons.
    for (let button of this.#navButtons) {
      button.onclick = this.handleNavigationButtonClick.bind(this);
    }
  }

  goToScreen(screenId) {
    for (let button of this.#navButtons) {
      button.classList.remove("active");
    }

    for (let screen of this.#screens) {
      screen.classList.remove("active");
    }

    const targetButton = document.querySelector(`footer button[data-screen="${screenId}"]`);
    if (targetButton) {
      targetButton.classList.add("active");
    }

    const targetScreen = document.getElementById(screenId);
    if (targetScreen) {
      targetScreen.classList.add("active");

      if (this.#sentryPageLoadSpan) {
        this.#sentryPageLoadSpan.updateName(screenId);
        this.#sentryPageLoadSpan.setAttribute(Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE, "route");
        this.#sentryPageLoadSpan = undefined;
      } else {
        Sentry.startBrowserTracingNavigationSpan(this.#sentryClient, {
          op: "navigation",
          name: screenId,
          attributes: {
            [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "route",
          }
        });
      }
    }

    switch (screenId) {
      case "friend-screen":
        this.#updateFriendsList()
        break;
      case "leaderboard-screen":
        this.#updateLeaderboard()
        break;
      default:
        break;
    }
  }

  /**
   * Handles the click event for buttons, toggling the active class on buttons and associated screens.
   *
   * @param {Event} event - The click event triggered by the button.
   * @return {void}
   */
  handleNavigationButtonClick(event) {
    const button = event.target.closest("button");
    if (!button) return;

    // Remove active class from all buttons and screens.
    for (let button of this.#navButtons) {
      button.classList.remove("active");
    }

    for (let screen of this.#screens) {
      screen.classList.remove("active");
    }

    // Add active class to the clicked button and associated screen.
    button.classList.add("active");

    const targetScreenId = button.dataset['screen'];

    const targetScreen = document.getElementById(targetScreenId);
    if (targetScreen) {
      targetScreen.classList.add("active");

      if (this.#sentryPageLoadSpan) {
        this.#sentryPageLoadSpan.updateName(targetScreenId);
        this.#sentryPageLoadSpan.setAttribute(Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE, "route");
        this.#sentryPageLoadSpan = undefined;
      } else {
        Sentry.startBrowserTracingNavigationSpan(this.#sentryClient, {
          op: "navigation",
          name: targetScreenId,
          attributes: {
            [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "route",
          }
        });
      }
    }

    // Render friends or leaderboard screen based on the button clicked.
    switch (targetScreenId) {
      case "friend-screen":
        this.#updateFriendsList()
        break;
      case "leaderboard-screen":
        this.#updateLeaderboard()
        break;
      default:
        break;
    }
  }

//region utils


handleShowMessage(isSuccess, itemType, message) {
	this.showMessage(isSuccess, itemType, message);
}

// TODO: Implement the showMessage method. This method should display a message to the user based on the success status of the operation.
showMessage(isSuccess, itemType, message, callback) {
	if (message == null || 'string' !== typeof message || 0 === message.length) {
		message = '';
	}
	console.log('showMessage', isSuccess, itemType, message);
}

  async #initMainScreen() {
    this.#userNameElement = document.getElementById("main-screen-username");
    if (this.#userNameElement) {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
        this.#userNameElement.textContent = `${window.Telegram.WebApp.initDataUnsafe.user.first_name} ${window.Telegram.WebApp.initDataUnsafe.user.last_name}`;
      } else {
        this.#userNameElement.textContent = "";
      }
    }

    this.#userBalanceElement = document.getElementById("balance");
    this.#spinner = document.getElementById("spinner");
    this.#userEnergyValueElement = document.getElementById("main-screen-energy-value");
    this.#userEnergyMaxElement = document.getElementById("main-screen-energy-max");
    this.#userEnergyBarElement = document.getElementById("main-screen-energy-bar");
    this.#userEnergyBarFillElement = document.getElementById("main-screen-energy-bar-fill");



    // return this.#backendDriver.getScore()
    //   .then(response => {
    //     this.updateScore(response["score"]);
    //     this.updateEnergy(response["energy"], response["energyMax"]);
    //   })
    //   .catch(error => {
    //     console.error('Failed to load balance', error);
    //   });
  }

  setBoosterActive(active) {
    this.#boosterActive = active;

    if (active) {
      if (this.#spinner && this.#spinner.classList) {
        this.#spinner.classList.add("boosted");
      }

      if (this.#userEnergyBarElement && this.#userEnergyBarElement.classList) {
        this.#userEnergyBarElement.classList.add("boosted");
      }

      // if (this.#fxDriver) {
      //   this.#fxDriver.startLightning();
      // }
    } else {
      if (this.#spinner && this.#spinner.classList) {
        this.#spinner.classList.remove("boosted");
      }

      if (this.#userEnergyBarElement && this.#userEnergyBarElement.classList) {
        this.#userEnergyBarElement.classList.remove("boosted");
      }

      // if (this.#fxDriver) {
      //   this.#fxDriver.stopLightning();
      // }
    }
  }

  /**
   * Updates the spin count displayed on the UI.
   * @param {number} score - The number of spins to display.
   */
  updateScore(score) {
    if (!this.#userBalanceElement) {
      return;
    }

    const oldScore = parseInt(this.#userBalanceElement.textContent, 10) || 0;
    const newScore = score ? score : 0;
    const delta = newScore - oldScore;
    const duration = 500; // Duration of the animation in milliseconds
    const frameRate = 60; // Frames per second
    const totalFrames = (duration / 1000) * frameRate;
    let currentFrame = 0;

    const animateScore = () => {
      if (currentFrame <= totalFrames) {
        const interpolatedScore = Math.round(oldScore + (delta * (currentFrame / totalFrames)));
        this.#userBalanceElement.textContent = `${interpolatedScore}`;
        currentFrame++;
        requestAnimationFrame(animateScore);
      } else {
        this.#userBalanceElement.textContent = `${newScore}`;
      }
    };

    animateScore();
  }

  spawnScoreParticle() {
    this.#fxDriver.showScore(this.#spinner, window.localStorage.getItem('upgrades.spinMultiplier'), this.#boosterActive);
  }

  updateEnergy(current, max) {
    if (this.#userEnergyValueElement) {
      //this.#userEnergyValueElement.textContent = `${current}`;
      const oldEnergy = parseInt(this.#userEnergyValueElement.textContent, 10) || 0;
      const delta = current - oldEnergy;
      const durationEn = 250; // Duration of the animation in milliseconds
      const frameRate = 60; // Frames per second
      const totalFrames = (durationEn / 1000) * frameRate;
      let currentFrame = 0;

      const animateScore = () => {
        if (currentFrame <= totalFrames) {
          const interpolatedScore = Math.round(oldEnergy + (delta * (currentFrame / totalFrames)));
          this.#userEnergyValueElement.textContent = `${interpolatedScore}`;
          currentFrame++;
          requestAnimationFrame(animateScore);
        } else {
          //this.#userEnergyValueElement.textContent = `${current}`;
        }
      };

      animateScore();
    }

    if (this.#userEnergyMaxElement) {
      this.#userEnergyMaxElement.textContent = `${max}`;
    }

    if (this.#userEnergyBarFillElement) {
      this.#userEnergyBarFillElement.style.width = `${(current / max) * 100}%`;
    }

    if (this.#spinner) {
      if (current >= 0) {
        const opacity = this.#spinnerOpacity > 1 ? 1 : this.#spinnerOpacity < 0 ? 0 : this.#spinnerOpacity;
        const saturation = this.#spinnerSaturation > 100 ? 100 : this.#spinnerSaturation < 0 ? 0 : this.#spinnerSaturation;
        this.#spinner.style.opacity = `${opacity}`;
        this.#spinner.style.filter = `saturate(${saturation}%)`;
      } else {
        const opacity = this.#spinnerOpacityEmpty > 1 ? 1 : this.#spinnerOpacityEmpty < 0 ? 0 : this.#spinnerOpacityEmpty;
        const saturation = this.#spinnerSaturationEmpty > 100 ? 100 : this.#spinnerSaturationEmpty < 0 ? 0 : this.#spinnerSaturationEmpty;
        this.#spinner.style.opacity = `${opacity}`
        this.#spinner.style.filter = `saturate(${saturation}%)`;
      }
    }
  }

  async #initInviteButton() {
    this.#inviteButton = document.getElementById("friend-invite-button");

    // Set up event listeners for share button.

    if (this.#inviteButton) {
      this.#inviteButton.onclick = async () => {
        if (window.Telegram && window.Telegram.WebApp) {
          let referralCode = localStorage.getItem('referralCode');
          if (null == referralCode || 'string' !== typeof referralCode || 0 === referralCode.length) {
            referralCode = await this.#backendDriver.getCode();
            if (null == referralCode || 'string' !== typeof referralCode || 0 === referralCode.length) {
              console.error('Failed to get referral code');
              return;
            }
          }
          const text = await this.#i18nDriver.translate("invite-share-text");
          const url = `https://t.me/SpinnerSpinsDoughRollsBot/Spinner?startapp=${referralCode}`;
          window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`);
        }
      }
    }
  }

  /**
   * Renders a friend list in the UI.
   */
  #updateFriendsList(friends) {
    this.#backendDriver.getFriends()
      .then(response => {
        //region Total Friends

        let totalFriendNum = response.totalFriends;
        const totalFriendsElement = document.getElementById('friend-screen-total-friends');
        if (totalFriendsElement) {
          if (null == totalFriendNum) {
            totalFriendsElement.textContent = '0';
          } else {
            totalFriendsElement.textContent = totalFriendNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        }

        //endregion

        //region Total Friend Bonus

        const totalFriendBonus = response.totalRewards;
        const totalFriendBonusElement = document.getElementById('friend-screen-total-bonus');
        if (totalFriendBonusElement) {
          if (null == totalFriendBonus || 'number' !== typeof totalFriendBonus) {
            totalFriendBonusElement.textContent = '0';
          } else {
            totalFriendBonusElement.textContent = totalFriendBonus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        }

        //endregion

        //region Friends List

        const friends = response.friends;
        const itemTemplate = document.getElementById('template-friend-item');
        const container = document.getElementById('friend-list');

        container.innerHTML = '';

        if (friends != null) {
          for (let i = 0; i < friends.length; i++) {
            if (null == friends[i]) {
              continue;
            }

            const item = itemTemplate.content.cloneNode(true);
            if (null == friends[i].tg_name) {
              item.querySelector('.name').textContent = this.#i18nDriver.translate('anonymous-tg-username');
            } else {
              item.querySelector('.name').textContent = friends[i].tg_name;
            }

            if (null == friends[i].spins || 'number' !== typeof friends[i].spins) {
              item.querySelector('.balance > span').textContent = '0';
            } else {
              item.querySelector('.balance > span').textContent = friends[i].spins;
            }

            container.appendChild(item);
          }
        }

        //endregion
      });
  }

  /**
   * Renders Leaderboard in the UI.
   */
  #updateLeaderboard() {
    this.#backendDriver.getLeaderboard()
      .then(response => {
        //region Total Players

        let totalPlayerNum = response.totalPlayers;
        const totalPlayersElement = document.getElementById('leaderboard-total-players');
        if (totalPlayersElement) {
          if (null == totalPlayerNum || 'number' !== typeof totalPlayerNum) {
            totalPlayersElement.textContent = '0';
          } else {
            if (totalPlayerNum > 1000) {
              totalPlayersElement.textContent = totalPlayerNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            } else {
              totalPlayersElement.textContent = totalPlayerNum;
            }
          }
        }

        //endregion

        //region Leaderboard List

        const leaderboard = response.leaderboard;
        const itemTemplate = document.getElementById('template-leaderboard-item');
        const container = document.getElementById('leaderboard-list');

        container.innerHTML = '';

        for (let i = 0; i < leaderboard.length; i++) {
          if (null == leaderboard[i]) {
            continue;
          }

          const item = itemTemplate.content.cloneNode(true);
          item.querySelector('.rating').textContent = i + 1;
          if (null == leaderboard[i].tg_name) {
            item.querySelector('.name').textContent = 'Anonymous'; // todo: i18n
          } else {
            item.querySelector('.name').textContent = leaderboard[i].tg_name;
          }

          if (null == leaderboard[i].spins) {
            item.querySelector('.balance > span').textContent = '0';
          } else {
            item.querySelector('.balance > span').textContent = leaderboard[i].spins;
          }

          container.appendChild(item);
        }

        //endregion
      })
      .catch(error => {
        console.error('Failed to load leaderboard', error);
      });
  }

  async #initItemShowcase() {

	  this.allItems = await this.#backendDriver.getAllItems();
	  this.playerItems = await this.#backendDriver.getPlayerItems();

	  let elements = this.#storeDailyBoostContainerElement.querySelectorAll("a.item.inventory-item")
	  for (let element of elements) {
		  element.remove();
	  }

	  let itemShowcase = document.getElementById('inventory-item-container');
	  let itemTemplate = document.getElementById('template-inventory-item');



	  console.log(this.playerItems);
	  for (let [key, value] of this.playerItems) {
		  const itemElement = itemTemplate.content.cloneNode(true);
		  //await this.#i18nDriver.applyTranslationsToElement(itemElement);

		  const rootElement = itemElement.querySelector('a.item.inventory-item');
		  const iconElement = itemElement.querySelector('.icon');
		  if (iconElement) {
			  iconElement.src="/test_Bck_icon.jpg";
		  }
		  itemShowcase.appendChild(itemElement);



		  rootElement.onclick = () => {
			  console.log('Item Clicked', key);
			  this.applySkin(key);
		  }

	  }

  }

  async applySkin(itemID) {
	  console.log(itemID);
	  let item = this.playerItems.get(itemID);
	  console.log(item);


	  let id = '00000000-0000-0000-0000-000000000000';
	  let t = 0;
	  switch (item.type) {
		  case 0:
			  t = 0;
			  if (this.activeSpinnerSkin != null) {
				  if (this.activeSpinnerSkin === item.id) {
					  //reset skin to default
					  this.applySpinnerSkin("/spinner.png");
					  this.activeSpinnerSkin = '00000000-0000-0000-0000-000000000000';

					  break;
				  } else {
					  //apply new skin
					  this.activeSpinnerSkin = item.id;
					  this.applySpinnerSkin(item.item_url);
					  id = item.id;
					  break;
				  }
			  } else {
				  this.activeSpinnerSkin = item.id;
				  this.applySpinnerSkin(item.item_url);
				  id = item.id;
				  break;
			  }
		  case 1:
			  t = 1;
			  if (this.activeBackground != null) {
				  if (this.activeBackground === item.id) {
					  //reset skin to default
					  this.activeBackground = '00000000-0000-0000-0000-000000000000';
					  this.applyBackground("/background.jpg");
					  break;
				  } else {
					  //apply new skin
					  this.activeBackground = item.id;
					  this.applyBackground(item.item_url);
					  id = item.id;
					  break;
				  }
			  } else {
				  this.activeBackground = item.id;
				  this.applyBackground(item.item_url);
				  id = item.id;
				  break;
			  }
	  }

	  let data =
		  {
			  t: MessageTypes.TOGGLE_SKIN,
			  d: {
				  id: id,
				  t: t
			  }
		  };

	  console.log(data);
	  await this.#backendDriver.sendData(JSON.stringify(data));
  }

	applySpinnerSkin(skinPath) {
		console.log(skinPath);
		this.#spinner.src = `${skinPath}`;
	}

	applyBackground(skinPath) {
	  	console.log(skinPath);
		document.body.style.backgroundImage = `url(${skinPath})`;
}


  async #initStoreScreen() {
    await this.#initDailyBoostListItems();
    await this.#initUpgradeListItems();
    this.#initStoreConfirmation();
  }

  async refreshStore() {
    await this.#initStoreScreen();
  }

  async #initDailyBoostListItems() {
    this.#storeDailyBoostContainerElement = document.getElementById("daily-item-container");
    this.#storeDailyBoostItemTemplate = document.getElementById("template-daily-item");

    if (this.#storeDailyBoostContainerElement && this.#dailyItemList && this.#dailyItemList.length > 0) {
      //Clear the store container
      let elements = this.#storeDailyBoostContainerElement.querySelectorAll("a.item.store-item")
      for (let element of elements) {
        element.remove();
      }

      for (let item of this.#dailyItemList) {
        const itemElement = this.#storeDailyBoostItemTemplate.content.cloneNode(true);
        await this.#i18nDriver.applyTranslationsToElement(itemElement);

        const rootElement = itemElement.querySelector('a.item.store-item');
        if (rootElement) {
          rootElement.dataset['id'] = item.id;
        }

        let dailyAvailable = 0;
        let dailyMax = 0;

        switch (item.id) {
          case 1:
            dailyMax = window.localStorage.getItem('consumable.spinBoosterMax') || 0;
            dailyAvailable = dailyMax - window.localStorage.getItem('consumable.spinBoosterUsed') || 0;
			item.description = await this.#i18nDriver.translate('daily-boost-turbo-description');
            break;
          case 2:
            dailyMax = window.localStorage.getItem('consumable.energyRechargeMax') || 0;
            dailyAvailable = dailyMax - window.localStorage.getItem('consumable.energyRechargeUsed') || 0;
			item.description = await this.#i18nDriver.translate('daily-boost-recharge-description');
            break;
        }
        if (dailyAvailable < 0) {
          dailyAvailable = 0;
        }

        const availableElement = itemElement.querySelector('.daily-available');
        if (availableElement) {
          availableElement.textContent = `${dailyAvailable}`;
        }

        const maxElement = itemElement.querySelector('.daily-max');
        if (maxElement) {
          maxElement.textContent = `${dailyMax}`;
        }

        const iconElement = itemElement.querySelector('.daily-icon');
        if (iconElement) {
          iconElement.textContent = item.icon;
        }

        const headerElement = itemElement.querySelector('.daily-header');
        if (headerElement) {
          headerElement.textContent = await this.#i18nDriver.translate(item.title);
        }

		const descriptionElement = itemElement.querySelector('.daily-description');
		if (descriptionElement) {
			//descriptionElement.textContent = await this.#i18nDriver.translate(item.description);
		}

		if (dailyAvailable > 0) {
			rootElement.onclick = () => {
				console.log('Daily boost clicked', item);
				this.#showStoreConfirmation(rootElement, item,
					() => this.#onDailyBoostPurchaseConfirmed(item),
					this.#onDailyBoostPurchaseCancelled.bind(this));
			}
		} else {

			rootElement.style.filter = `saturate(${0.5}%)`;
		}

        this.#storeDailyBoostContainerElement.appendChild(itemElement);
      }
    }
  }

  async #initUpgradeListItems() {
    this.#storeUpgradeContainerElement = document.getElementById("upgrade-item-container");
    this.#storeUpgradeItemTemplate = document.getElementById("template-upgrade-item");

    if (this.#storeUpgradeContainerElement && this.#upgradeItemList && this.#upgradeItemList.length > 0) {
      //Clear the store container
      let elements = this.#storeUpgradeContainerElement.querySelectorAll("a.item.store-item")
      for (let element of elements) {
        element.remove();
      }


      for (let item of this.#upgradeItemList) {
        const itemElement = this.#storeUpgradeItemTemplate.content.cloneNode(true);
        await this.#i18nDriver.applyTranslationsToElement(itemElement);

        const rootElement = itemElement.querySelector('a.item.store-item');
        if (rootElement) {
          rootElement.dataset['id'] = item.id;
        }

        let upgradeLevel = 0;

        switch (item.id) {
			case 1:
            upgradeLevel = window.localStorage.getItem('upgrades.spinMultiplier') || 0;
			item.description = await  this.#i18nDriver.translate('upgrade-multiplier-description');
			if (upgradeLevel >= 25) {
				upgradeLevel = 99;
			}
            break;
          case 2:
            upgradeLevel = window.localStorage.getItem('upgrades.energyRegen') || 0;
			item.description = await this.#i18nDriver.translate('upgrade-recharge-speed-description');
			  if (upgradeLevel >= 3) {
				  upgradeLevel = 99;
			  }
            break;
          case 3:
            upgradeLevel = window.localStorage.getItem('upgrades.energyCapacity') || 0;
			item.description = await this.#i18nDriver.translate('upgrade-max-energy-description');
			  if (upgradeLevel >= 25) {
				  upgradeLevel = 99;
			  }
            break;
        }

		if (upgradeLevel === 99) {
			const levelElement = itemElement.querySelector('.upgrade-level');
			if (levelElement) {
				levelElement.textContent = "MAX";
				rootElement.style.filter = `saturate(${0.5}%)`;
			}

		} else {
			item.level = upgradeLevel;
			item.value = Math.pow(2, upgradeLevel) * 1000;



			const headerElement = itemElement.querySelector('.upgrade-header');
			if (headerElement) {
				headerElement.textContent = await this.#i18nDriver.translate(item.title);
			}

			const valueElement = itemElement.querySelector('.upgrade-value');
			if (valueElement) {
				valueElement.textContent = item.value;
			}

			const levelElement = itemElement.querySelector('.upgrade-level');
			if (levelElement) {
				levelElement.textContent = item.level;
			}

			rootElement.onclick = () => {
				console.log('Upgrade clicked', item);
				this.#showStoreConfirmation(rootElement, item,
					() => this.#onUpgradePurchaseConfirmed(item),
					this.#onUpgradePurchaseCancelled.bind(this));
			}
		}

		const iconElement = itemElement.querySelector('.upgrade-icon');
		if (iconElement) {
			iconElement.textContent = item.icon;
		}

        this.#storeUpgradeContainerElement.appendChild(itemElement);
      }
    }
  }

  #initStoreConfirmation() {
    this.#storeConfirmation = document.getElementById("store-confirmation");
    if (this.#storeConfirmation) {
      this.#storeConfirmation.classList.remove("active");
      this.#storeConfirmation.onclick = () => {
        console.log('Store confirmation clicked');
        this.#hideStoreConfirmation();
      }
    }

    this.#storeConfirmButton = document.getElementById("store-screen-confirm-button");
    this.#storeCancelButton = document.getElementById("store-screen-cancel-button");
    this.#storeConfirmationItemIcon = document.getElementById("store-confirmation-icon");
    this.#storeConfirmationItemTitle = document.getElementById("store-confirmation-header");
    this.#storeConfirmationItemValue = document.getElementById("store-confirmation-value");
    this.#storeConfirmationCoin = document.getElementById("store-confirmation-coin");
    this.#storeConfirmationItemSeparator = document.getElementById("store-confirmation-separator");
    this.#storeConfirmationItemLevel = document.getElementById("store-confirmation-level");
    this.#storeConfirmationItemLevelLabel = document.getElementById("store-confirmation-level-label");
    this.#storeConfirmationItemDescription = document.getElementById("store-confirmation-description");
  }

  async #showStoreConfirmation(item, storeItem, confirmCallback, cancelCallback) {
    if (this.#storeConfirmationTransitioning) {
      return;
    }

    if (storeItem.title) {
      this.#storeConfirmationItemTitle.textContent = await this.#i18nDriver.translate(storeItem.title);
    } else {
      this.#storeConfirmationItemTitle.textContent = "";
    }

    /** @type {boolean} */
    let showSeparator;

    if (storeItem.value) {
      this.#storeConfirmationItemValue.textContent = storeItem.value;
      this.#storeConfirmationItemValue.classList.add("active");
      this.#storeConfirmationCoin.classList.add("active");
      showSeparator = true;
    } else {
      this.#storeConfirmationItemValue.textContent = "";
      this.#storeConfirmationItemValue.classList.remove("active");
      this.#storeConfirmationCoin.classList.remove("active");
      showSeparator = false;
    }

    if (storeItem.level != null) {
      this.#storeConfirmationItemLevel.textContent = storeItem.level ? "" + (parseInt(storeItem.level, 10) + 1) : 0;
      this.#storeConfirmationItemLevel.classList.add("active");
      this.#storeConfirmationItemLevelLabel.classList.add("active");
      showSeparator &= true;
    } else {
      this.#storeConfirmationItemLevel.textContent = "";
      this.#storeConfirmationItemLevel.classList.remove("active");
      this.#storeConfirmationItemLevelLabel.classList.remove("active");
      showSeparator &= false;
    }

    if (showSeparator) {
      this.#storeConfirmationItemSeparator.classList.add("active");
    } else {
      this.#storeConfirmationItemSeparator.classList.remove("active");
    }


    this.#storeConfirmationItemDescription.textContent = storeItem.description ? storeItem.description : "";
    this.#storeConfirmationItemIcon.textContent = storeItem.icon ? storeItem.icon : "";

    this.#storeConfirmationTransitioning = true;

    setTimeout(() => {
      this.#storeConfirmationTransitioning = false;
    }, this.#storeConfirmationTransitionDelay);

    if (this.#storeConfirmation) {
      this.#storeConfirmation.classList.remove("hidden");
      this.#storeConfirmation.classList.add("active");
    }

    if (this.#storeConfirmButton) {
      this.#storeConfirmButton.onclick = () => {
        confirmCallback();
        this.#hideStoreConfirmation();
      }
    }

    if (this.#storeCancelButton) {
      this.#storeCancelButton.onclick = () => {
        cancelCallback();
        this.#hideStoreConfirmation();
      }
    }
  }

  #hideStoreConfirmation() {
    if (this.#storeConfirmationTransitioning) {
      return;
    }

    this.#storeConfirmationTransitioning = true;

    if (this.#storeConfirmation) {
      this.#storeConfirmation.classList.remove("active");

      setTimeout(() => {
        this.#storeConfirmation.classList.add("hidden");
        this.#storeConfirmationTransitioning = false;
      }, this.#storeConfirmationTransitionDelay);
    }

    if (this.#storeConfirmButton) {
      this.#storeConfirmButton.onclick = null;
    }

    if (this.#storeCancelButton) {
      this.#storeCancelButton.onclick = null;
    }
  }

  #onDailyBoostPurchaseConfirmed(item) {
    console.log('Daily boost purchased', item);
    if (item.id === 1) {
      console.log('Turbo');
      this.goToScreen('main-screen');
    }
    this.#backendDriver.useDailyBoost(item.id).then(r => {
      console.log('Daily boost purchased', r);
    });
  }

//endregion

//region Options Screen

  #onDailyBoostPurchaseCancelled() {
  }

  #onUpgradePurchaseConfirmed(item) {
    console.log('Upgrade purchase', item);
    this.#backendDriver.buyUpgrade(item.id).then(r => {
      console.log('Upgrade purchased', r);
	  this.refreshStore();
    });
  }

  #onUpgradePurchaseCancelled() {
  }

  #initOptionsScreen() {
    this.#hapticsControl = document.getElementById("settings-screen-switch-haptics");
    this.#audioControl = document.getElementById("settings-screen-switch-sound");
    this.#parallaxControl = document.getElementById("settings-screen-switch-parallax");

    // Set up event listeners for haptics control.
    if (this.#hapticsControl) {
      this.#hapticsControl.onclick = () => {
        this.#hapticsDriver.toggleHaptics();
        this.#updateHapticsUI();
      }
    } else {
      console.warn("Haptics control not found!");
    }

    // Set up event listeners for audio control.
    if (this.#audioControl) {
      this.#audioControl.onclick = () => {
        this.#audioDriver.toggleAudio();
        this.#updateAudioUI();
      }
    } else {
      console.warn("Audio control not found!");
    }

    // Set up event listeners for parallax control.
    if (this.#parallaxControl) {
      this.#parallaxControl.onclick = () => {
        this.#orientationDriver.toggleParallax();
        this.#updateParallaxUI();
      }
    } else {
      // console.warn("Parallax control not found!");
    }

    // Update UI based on current settings.
    this.#updateHapticsUI();
    this.#updateAudioUI();
    this.#updateParallaxUI()
  }

  /**
   * Updates the audio control UI based on the current audio settings.
   */
  #updateAudioUI() {
    if (!this.#audioDriver) {
      return;
    }

    if (this.#audioDriver.isEnabled()) {
      this.#audioControl.classList.add("active");
      this.#audioControl.checked = true;
    } else {
      this.#audioControl.classList.remove("active");
      this.#audioControl.checked = false;
    }
  }

  /**
   * Updates the haptics control UI based on the current haptics settings.
   */
  #updateHapticsUI() {
    if (!this.#hapticsDriver) {
      return;
    }

    if (this.#hapticsDriver.isEnabled()) {
      this.#hapticsControl.classList.add("active");
      this.#hapticsControl.checked = true;
    } else {
      this.#hapticsControl.classList.remove("active");
      this.#hapticsControl.checked = false;
    }
  }

  /**
   * Updates the parallax control UI based on the current parallax settings.
   */
  #updateParallaxUI() {
    if (!this.#parallaxControl) {
      return;
    }

    if (this.#orientationDriver.isEnabled()) {
      this.#parallaxControl.classList.add("active");
      this.#parallaxControl.checked = true;
    } else {
      this.#parallaxControl.classList.remove("active");
      this.#parallaxControl.checked = false;
    }
  }

//endregion

}
