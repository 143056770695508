/**
 * The GameLogic class is responsible for handling all the core
 * mechanics and rules of the game.
 * It manages the game's state, processes player actions, and enforces the game's rules.
 */
import {MessageTypes} from "./backend-driver.js";
import {number} from "@tma.js/sdk";


export class GameMode {
  /**
   * The driver responsible for handling audio functionalities.
   * @type {?AudioDriver}
   */
  #audioDriver = null;
  /**
   * The driver responsible for handling haptics functionalities.
   * @type {?HapticsDriver}
   */
  #hapticsDriver = null;
  /**
   * The driver responsible for handling remote operations: send/receive data to/from game server.
   * @type {?BackendDriver}
   */
  #backendDriver = null;
  /**
   * The driver responsible for handling effects and animations.
   * @type {?FxDriver}
   */
  #fxDriver = null;
  /**
   * The game UI responsible for displaying the game state and user interface.
   * @type {?GameUI}
   */
  gameUI = null;
  /**
   * The spinner element used in the game which the player interacts with.
   * @type {HTMLElement}
   */
  #spinner = null;
  /**
   * True if the spinner is being dragged; otherwise, false.
   * @type {boolean}
   */
  isDraggingSpinner = false;
  /**
   * The number of spins the player has accumulated during game session.
   * @type {number}
   */
  #spinCounter = 0;
  /**
   * The current angular velocity of the spinner.
   * @type {number}
   */
  #angularVelocity = 0;
  /**
   * Last timestamp of the spinner rotation, used to track the time difference.
   * @type {DOMHighResTimeStamp}
   */
  #lastTimestamp = 0;
  /**
   * The current rotation angle of the spinner (rad).
   * @type {number}
   */
  #currentRotation = 0;
  /**
   * The last rotation angle of the spinner (rad).
   * @type {number}
   */
  #lastRotation = 0;

  // --- Properties ---
  #energy = 0;
  #energyMax = 0;
  #touchLastRotation = 0;
  #boosterActive = false
  /*
   * Inertia decay factor to slow down the spinner rotation.
   * @type {number}
   */
  #inertiaDecay = 0.995;
  /**
   * Maximum angular velocity of the spinner.
   * @type {number}
   */
  #maxAngularVelocity = 1800; // Define the maximum angular velocity
  #minAngularVelocity = 0.0001; // Define the minimum angular velocity
  #rawAngularVelocity = 0;
  #currentTimestamp = 0;
  #touchStartTime = 0;
  #touchEndTime = 0;
  #touchStartPosition = [0, 0];
  #touchEndPosition = [0, 0];
  #initData = "";
  #questData = {};

  /**
   * Initializes the game logic by setting up the game state and other necessary variables.
   * @param {GameUI} gameUI - The game's user interface.
   * @param {AudioDriver} audioDriver - The driver responsible for handling audio functionalities.
   * @param {HapticsDriver} hapticsDriver - The driver responsible for handling haptics functionalities.
   * @param {BackendDriver} backendDriver - The driver responsible for handling remote operations.
   * @param {FxDriver} fxDriver - The driver responsible for handling effects and animations.
   */
  init(gameUI, audioDriver, hapticsDriver, backendDriver, fxDriver) {
    this.gameUI = gameUI;
    this.#audioDriver = audioDriver;
    this.#hapticsDriver = hapticsDriver;
    this.#backendDriver = backendDriver;
    this.#fxDriver = fxDriver;
    this.#spinner = document.getElementById("spinner");
    if (window.Telegram && window.Telegram.WebApp) {
      this.#initData = window.Telegram.WebApp.initData;
    }

    this.gameUI.updateScore(window.localStorage.getItem('spins.count'));

    // Set the callback for receiving data
    this.#backendDriver.setReceiveDataCallback(this.handleReceivedData.bind(this));

    // --- Callbacks for user's input ---
    this.#spinner.addEventListener('touchstart', this.spinnerTouchStart.bind(this));
    //this.#spinCounter = window.localStorage.getItem('spins.count') || 0;





  }

  /**
   * Callback function for the touch start event on the spinner element.
   * @param {?TouchEvent} event - The touch start event.
   */
  spinnerTouchStart(event) {
    // console.log("spinnerTouchStart event triggered")
    if (!event) {
      return;
    }
    event.preventDefault();

    //Record where and when touch started
    this.#touchStartTime = Date.now();
    this.#touchStartPosition[0] = event.touches[0].clientX;
    this.#touchStartPosition[1] = event.touches[0].clientY;

    // Mark the spinner as being dragged.
    this.isDraggingSpinner = true;

    // Bind the event listeners to document to track the touch move and end events.
    document.ontouchmove = this.spinnerTouchMove.bind(this);
    document.ontouchend = this.spinnerTouchEnd.bind(this);

    this.#audioDriver.stopBearingSound();
    this.stopSpinning();
  }

  /**
   * Callback function for the touch move event on the spinner element.
   * @param {?TouchEvent|?MouseEvent} event - The touch move event.
   */
  spinnerTouchMove(event) {
    if (!event) {
      return;
    }

    if (!this.isDraggingSpinner) {
      return;
    }

    // Get the current angle of the spinner based on the touch event.
    this.#currentRotation = this.#getCurrentSpinnerAngleFromTouch(event);
    if (this.#currentRotation < 0) {
      this.#currentRotation += Math.PI * 2;
    }

    this.#touchEndPosition[0] = event.touches[0].clientX;
    this.#touchEndPosition[1] = event.touches[0].clientY;

    this.#spinner.style.transform = `rotate(${this.#radiansToDegrees(this.#currentRotation)}deg)`;

    let angleDiff = this.#currentRotation - this.#touchLastRotation;

    this.#currentTimestamp = Date.now();
    let timeDiff = this.#currentTimestamp - this.#lastTimestamp;
    this.#angularVelocity = angleDiff / timeDiff; // Reset angular velocity on every move
    this.#angularVelocity = Math.min(this.#angularVelocity, this.#maxAngularVelocity); // Cap angular velocity
    this.#lastTimestamp = this.#currentTimestamp;
    this.#touchLastRotation = this.#currentRotation;
  }

  /**
   * Callback function for the touch end event on the spinner element.
   * @param {?TouchEvent} event - The touch end event.
   */
  spinnerTouchEnd(event) {
    if (!event) {
      return;
    }

    event.preventDefault();
    this.isDraggingSpinner = false;

    // Unbind the event listeners for dragging the spinner.
    document.ontouchmove = null;
    document.ontouchend = null;

    // Check if we dragged enough?
    this.#touchEndTime = Date.now();
    const deltaTime = this.#touchEndTime - this.#touchStartTime;
    const deltaTouch = Math.sqrt(Math.pow(Math.abs(this.#touchEndPosition[0] - this.#touchStartPosition[0]), 2)) // + Math.pow(Math.abs(this.#touchEndPosition[1]-this.#touchStartPosition[1])));

    if ((deltaTime < 120 || deltaTouch < 15) && (this.#angularVelocity < 0.1)) {
      //return;
    }

    if (Math.abs(this.#angularVelocity) < 0.001) {
      this.#angularVelocity = 0;
      return;
    }

    window.requestAnimationFrame(this.spinnerRotateOneFrame.bind(this));
    this.#audioDriver.playBearingSound();

    console.log(`Start angular velocity: ${this.#angularVelocity}, angle: ${this.#currentRotation}, last angle: ${this.#lastRotation}`);

    // send spins data to server
    this.startSpinning();
  }

  /**
   * RPC to server to start spinning
   */
  async startSpinning() {
    try {
      const speed = this.#angularVelocity;
      const rotation = this.#currentRotation;

      console.log(`Start spinning with speed: ${speed}, rotation: ${rotation}`);

      let data =
        {
          t: MessageTypes.START_ROTATING,
          d: {
            spd: speed,
            rt: rotation,
            ts: Date.now()
          }
        };

      await this.#debugSendData(JSON.stringify({
        t: "start",
        currentRotation: rotation,
        angularVelocity: speed,
        // timeDiff: timeDiff,
        // rotationDegrees: rotationDegrees,
        lastTimestamp: this.#lastTimestamp
      }));

      return this.#backendDriver.sendData(JSON.stringify(data))
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * RPC to server to stop spinning
   */
  async stopSpinning() {
    try {
      if (this.#audioDriver) {
        this.#audioDriver.stopBearingSound();
      }

      // if (this.#boosterActive) {
        // if (this.#fxDriver) {
        //   this.#fxDriver.stopLightning();
        // }
      // }

      this.#angularVelocity = 0;

      let data =
        {
          t: MessageTypes.STOP_ROTATING,
          d: {
            spd: 0,
            rt: this.#currentRotation,
            ts: Date.now()
          }
        };

      await this.#backendDriver.sendData(JSON.stringify(data));
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async #debugSendData(data) {
    if (this.#backendDriver) {
      return this.#backendDriver.sendData(`{"t":-1,"d":${data}}`);
      // return (new Promise((resolve, reject) => {
      //   resolve();
      // }));
    }
    return Promise.reject(new Error("Backend driver is not available!"));
  }

  /**
   * Rotates the spinner frame based on the current time.
   * @param {DOMHighResTimeStamp} time
   */
  async spinnerRotateOneFrame(time) {
    if (!this.#spinner) {
      return;
    }

    // If the spinner is being dragged manually, do not animate the rotation and score spins.
    if (this.isDraggingSpinner) {
      return;
    }

    if (time <= 0) {
      console.warn("Invalid time value!");
      return;
    }

    // Stop the rotation (and frame updates) if the angular velocity is too low.
    if (Math.abs(this.#angularVelocity) < this.#minAngularVelocity) {
      this.#angularVelocity = 0;
      this.stopSpinning();
      if (this.#audioDriver) {
        this.#audioDriver.stopBearingSound();
      }
      return;
    } else {
      if (this.#audioDriver) {
        this.#audioDriver.updateSpinnerAudioVolume(this.#angularVelocity);
      }
    }

    // Calculate the time difference between the current and last frame.
    //const timeDiff = time - this.#lastTimestamp;
    const timeDiff = Date.now() - this.#lastTimestamp;

    // Update the rotation angle based on the angular velocity.
    this.#currentRotation += this.#angularVelocity * timeDiff;
    this.#angularVelocity *= this.#inertiaDecay;

    // Constrain the rotation angle to the range [0, 360).
    const rotationDegrees = this.#radiansToDegrees(this.#currentRotation) % 360;
    this.#spinner.style.transform = `rotate(${rotationDegrees}deg`;

    // Full rotation (2π radians) triggers click sound and haptic feedback.
    if (Math.abs(this.#currentRotation) >= Math.PI * 2) {
      // Reset the rotation angle to keep it within the range [0, 2π).
      this.#currentRotation = this.#currentRotation % (Math.PI * 2);
      if ((this.#energy - Number(window.localStorage.getItem('upgrades.spinMultiplier')) >= 0) || this.#boosterActive) {
        // Play the click sound and haptic feedback on every full rotation
        if (this.#audioDriver) {
          this.#audioDriver.playClickSound();
        }
        if (this.#hapticsDriver) {
          this.#hapticsDriver.playHaptic();
        }
        this.gameUI.spawnScoreParticle();
      }
    }

    // Update the last timestamp for the next frame.
    this.#lastTimestamp = Date.now();

    // if (!this.#boosterActive) {
    //   if (this.#fxDriver) {
    //     this.#fxDriver.stopLightning();
    //   }
    // }

    // await this.#debugSendData(JSON.stringify({
    //   t: "frame",
    //   currentRotation: this.#currentRotation,
    //   angularVelocity: this.#angularVelocity,
    //   lastTimestamp: timeDiff
    // }));

    // Request our logic for next 'tick'
    window.requestAnimationFrame(this.spinnerRotateOneFrame.bind(this));
  }

  #radiansToDegrees(radians) {
    if (typeof radians !== "number") {
      throw new Error("Invalid radians value!");
    }

    return radians * (180 / Math.PI);
  }

  #degreesToRadians(degrees) {
    if (typeof degrees !== "number") {
      throw new Error("Invalid degrees value!");
    }

    return degrees * (Math.PI / 180);
  }

  /**
   * Returns the current spinner angle based on the touch event.
   * @param {?TouchEvent|?MouseEvent} event - The touch event.
   * @return {number}
   */
  #getCurrentSpinnerAngleFromTouch(event) {
    if (!event) {
      throw new Error("Invalid touch event!");
    }

    if (!this.#spinner) {
      throw new Error("Spinner element is not available!");
    }

    // Get the center of the spinner element.
    let rect = this.#spinner.getBoundingClientRect();
    let centerX = rect.left + rect.width / 2;
    let centerY = rect.top + rect.height / 2;

    // Get the client coordinates of the touch event.
    let clientX, clientY;
    if (event.touches) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    // Calculate the angle in radians from the center of the spinner to the touchpoint.
    return Math.atan2(clientY - centerY, clientX - centerX);
  }

  /**
   * Handles data received from the WebSocket.
   * @param {Object} data - The data received from the WebSocket.
   */
  async handleReceivedData(data) {
	  let jsonData;
	  try {
		  jsonData = JSON.parse(data.data);
	  } catch (error) {
		  console.error("Error parsing JSON data:", error);
		  return;
	  }
	  console.log("Data received in GameMode:", jsonData);
	  let type = jsonData.t;
	  let dataReceived = jsonData.d;


	  //console.log("Type received in GameMode:", jsonData.t);
	  //console.log("Data received in GameMode:", jsonData.d);

	  switch (type) {
		  case MessageTypes.SPINS:
			  //console.log("Spins: ", dataReceived.sp);
			  this.#spinCounter = dataReceived.sp;
			  this.gameUI.updateScore(this.#spinCounter, 0);
			  break
		  case MessageTypes.PLAYER_DATA:
			  // Write recieved data to local storage
			  console.log("Data: ", dataReceived);
			  window.localStorage.setItem('spins.count', dataReceived.sp);
			  window.localStorage.setItem('energy.max', dataReceived.em);
			  window.localStorage.setItem('upgrades.energyCapacity', dataReceived.ecl);
			  window.localStorage.setItem('upgrades.spinMultiplier', dataReceived.sm);
			  window.localStorage.setItem('upgrades.energyRegen', dataReceived.er);
			  window.localStorage.setItem('consumable.energyRechargeUsed', dataReceived.erU);
			  window.localStorage.setItem('consumable.energyRechargeMax', dataReceived.erD);
			  window.localStorage.setItem('consumable.spinBoosterUsed', dataReceived.sbU);
			  window.localStorage.setItem('consumable.spinBoosterMax', dataReceived.sbD);
			  if (dataReceived.Skn !== "") {
				  this.gameUI.applySkin(dataReceived.Skn);
			  }
			  if (dataReceived.Bck !== "") {
				  this.gameUI.applySkin(dataReceived.Bck);
			  }

			  this.#energy = Number(dataReceived.e);
			  this.#energyMax = Number(dataReceived.em)
			  this.gameUI.updateScore(Number(dataReceived.sp));
			  this.gameUI.updateEnergy(this.#energy, this.#energyMax);
			  this.gameUI.refreshStore()
			  break;

		  case MessageTypes.ENERGY:
			  this.#energy = Number(dataReceived.e);
			  //console.log("Energy: ", this.#energy);
			  this.gameUI.updateEnergy(this.#energy, this.#energyMax);
			  break;

		  case MessageTypes.UPGRADES:
			  window.localStorage.setItem('spins.count', dataReceived.sp);
			  window.localStorage.setItem('energy.max', dataReceived.em);
			  window.localStorage.setItem('upgrades.energyCapacity', dataReceived.ecl);
			  window.localStorage.setItem('upgrades.spinMultiplier', dataReceived.sm);
			  window.localStorage.setItem('upgrades.energyRegen', dataReceived.er);
			  this.gameUI.refreshStore()
			  this.#energyMax = Number(window.localStorage.getItem('energy.max'))
			  this.gameUI.updateEnergy(this.#energy, this.#energyMax);
			  break;

		  case MessageTypes.CONSUMABLES:
			  console.log("Consumables: ", dataReceived);
			  window.localStorage.setItem('consumable.energyRechargeUsed', dataReceived.erU);
			  window.localStorage.setItem('consumable.spinBoosterUsed', dataReceived.sbU);
			  this.gameUI.refreshStore()
			  break;

		  case MessageTypes.SPIN_MULTIPLIER:
			  window.localStorage.setItem('upgrades.spinMultiplier', dataReceived.sm);
			  this.#boosterActive = dataReceived.actv;
			  this.gameUI.setBoosterActive(dataReceived.actv);
			  break;
	  }
  }
}
