export class FxDriver {

  #particleNumMin = 17;
  #particleNumMax = 77;
  #particleNum = 17;
  #maxParticleSize = 7;
  #maxParticleDelayMs = 7777;
  #maxScoreParticles = 100;

  init() {
    this.#particleNum = Math.floor(Math.random() * (this.#particleNumMax - this.#particleNumMin + 1)) + this.#particleNumMin;
    this.createStars("fx-stars", this.#particleNum, this.#maxParticleSize, this.#maxParticleDelayMs);
    this.createScorePool("fx-score-pool", 100);
    this.createBoostParticles("fx-boost-particle-container", 100);
    // this.lightningEffect = new LightningEffect("fx-lightning", {
    //   size: 500,
    //   center: {x: 250, y: 20},
    //   minSegmentHeight: 5,
    //   groundHeight: 480,
    //   color: "hsl(180, 80%, 80%)",
    //   roughness: 2,
    //   maxDifference: 100,
    // });
  }

  createParticle(id) {
    const delay = Number((Math.random() * this.#maxParticleDelayMs).toFixed());
    const size = 1 + Number((Math.random() * (this.#maxParticleSize - 1)).toFixed());

    const particleDiv = document.createElement('div');
    particleDiv.id = "fx-star-" + id
    particleDiv.className = "fx-star";
    particleDiv.style.top = 5 + (Math.random() * 85) + "%";
    particleDiv.style.left = 5 + (Math.random() * 90) + "%";
    particleDiv.style.width = size + "px";
    particleDiv.style.height = size + "px";

    let color = Number((size * 192 / this.#maxParticleSize).toFixed()).toString(16);
    color = "#" + color + "" + color + "" + color;
    particleDiv.style.background = color;

    particleDiv.style.background = "url('/fx-star-particle.png') no-repeat center center";
    particleDiv.style.backgroundSize = "contain";

    particleDiv.style.animationDelay = delay + "ms";
    particleDiv.style.WebkitAnimationDelay = delay + "ms";

    return particleDiv;
  }

  createStars(containerId) {
    const container = document.getElementById(containerId);

    for (let i = 0; i < this.#particleNumMin; i++) {
      container.appendChild(this.createParticle(i));
    }
  }

  // Create a pool of score elements with +1 and float up CSS animation.
  createScorePool(containerId, num) {
    const container = document.getElementById(containerId);

    for (let i = 0; i < num; i++) {
      const scoreEl = document.createElement('div');
      scoreEl.id = "fx-score-" + i;
      scoreEl.className = "fx-score";
      scoreEl.style.display = "none";
      scoreEl.innerHTML = "+1";
      container.appendChild(scoreEl);
    }
  }

  createBoostParticles(containerId, num) {
    const container = document.getElementById(containerId);

    for (let i = 0; i < num; i++) {
      const particleDiv = document.createElement('div');
      particleDiv.id = "fx-boost-" + i;
      particleDiv.className = "fx-boost-particle";
      particleDiv.style.left = (Math.random() * 100) + "%";
      particleDiv.style.animationDelay = (Math.random() * 1000) + "ms";
      container.appendChild(particleDiv);
    }
  }

  showScore(element, score, boosted) {
    const scoreEl = document.getElementById("fx-score-" + Math.floor(Math.random() * this.#maxScoreParticles));
    const rect = element.getBoundingClientRect();
    const radius = Math.random() * Math.min(rect.width, rect.height) / 2;
    const angle = Math.random() * Math.PI * 2;
    const x = rect.left + rect.width / 2 + radius * Math.cos(angle);
    const y = rect.top + rect.height / 2 + radius * Math.sin(angle);

    scoreEl.style.left = x + "px";
    scoreEl.style.top = y + "px";
    scoreEl.style.display = "flex";
    scoreEl.innerHTML = "+" + score;

    if (boosted) {
      scoreEl.classList.add("boosted");
    }

    scoreEl.addEventListener("animationend", function () {
      scoreEl.style.display = "none";
      scoreEl.classList.remove("boosted");
    });
  }

  startLightning() {
    // this.lightningEffect.start();
  }

  stopLightning() {
    // this.lightningEffect.stop();
  }
}

class LightningEffect {
  constructor(canvasId, config = {}) {
    const {
      size = 500,
      radius = 100,
      minSegmentHeight = 5,
      color = "hsl(112,100%,55%)",
      roughness = 2,
      maxDifference = 100,
    } = config;

    this.active = true;
    this.size = size;
    this.radius = radius;
    this.minSegmentHeight = minSegmentHeight;
    this.color = color;
    this.roughness = roughness;
    this.maxDifference = maxDifference;

    const canvas = document.getElementById(canvasId);
    canvas.width = size;
    canvas.height = size;
    this.ctx = canvas.getContext("2d");

    this.ctx.globalCompositeOperation = "lighter";
    this.ctx.strokeStyle = this.color;
    this.ctx.shadowColor = this.color;

    this.initBackground();
  }

  initBackground() {
    this.ctx.fillStyle = this.color;
    this.ctx.clearRect(0, 0, this.size, this.size);
    this.ctx.fillStyle = "transparent";
  }

  getRandomEdgePoint() {
    const edge = Math.floor(Math.random() * 4); // 0: top, 1: right, 2: bottom, 3: left
    const size = this.size;
    switch (edge) {
      case 0:
        return {x: Math.random() * size, y: 0}; // Top edge
      case 1:
        return {x: size, y: Math.random() * size}; // Right edge
      case 2:
        return {x: Math.random() * size, y: size}; // Bottom edge
      case 3:
        return {x: 0, y: Math.random() * size}; // Left edge
    }
  }

  getPointInClosestQuarter = (start, center, radius) => {
    const quarterAngles = [
      {min: 0, max: Math.PI / 2},        // NE
      {min: Math.PI / 2, max: Math.PI}, // SE
      {min: Math.PI, max: (3 * Math.PI) / 2}, // SW
      {min: (3 * Math.PI) / 2, max: 2 * Math.PI}, // NW
    ];

    const angle = Math.atan2(start.y - center.y, start.x - center.x);
    const normalizedAngle = angle < 0 ? angle + 2 * Math.PI : angle;

    const closestQuarter = quarterAngles.find(
      (q) => normalizedAngle >= q.min && normalizedAngle < q.max
    );

    const randomAngle =
      Math.random() * (closestQuarter.max - closestQuarter.min) +
      closestQuarter.min;
    const randomRadius = Math.random() * radius;

    return {
      x: center.x + randomRadius * Math.cos(randomAngle),
      y: center.y + randomRadius * Math.sin(randomAngle),
    };
  };

  getRandomPointInCircle(center) {
    const angle = Math.random() * Math.PI * 2;
    const r = Math.sqrt(Math.random()) * this.radius; // Uniformly distributed points
    if (!center) {
      center = {x: this.size / 2, y: this.size / 2};
    }
    console.log(center);
    return {
      x: center.x + r * Math.cos(angle),
      y: center.y + r * Math.sin(angle),
    };
  }

  createLightning() {
    const start = this.getRandomEdgePoint();
    let center = {x: this.size / 2, y: this.size / 2};
    const end = this.getPointInClosestQuarter(start, center, this.radius);

    let lightning = [start, end];
    let segmentHeight = Math.hypot(end.x - start.x, end.y - start.y);
    let currDiff = this.maxDifference;

    while (segmentHeight > this.minSegmentHeight) {
      const newSegments = [];
      for (let i = 0; i < lightning.length - 1; i++) {
        const p1 = lightning[i];
        const p2 = lightning[i + 1];
        const midX = (p1.x + p2.x) / 2;
        const midY = (p1.y + p2.y) / 2;
        const newX = midX + (Math.random() * 2 - 1) * currDiff;
        newSegments.push(p1, {x: newX, y: midY});
      }

      newSegments.push(lightning.pop());
      lightning.length = 0;
      lightning.push(...newSegments);

      currDiff /= this.roughness;
      segmentHeight /= 2;
    }
    return lightning;
  }

  stop() {
    this.active = false;
  }

  start() {
    this.active = true;
    this.render();
  }

  render() {
    this.ctx.shadowBlur = 0;
    this.ctx.globalCompositeOperation = "source-over";
    this.ctx.clearRect(0, 0, this.size, this.size);
    this.ctx.fillRect(0, 0, this.size, this.size);
    this.ctx.globalCompositeOperation = "lighter";
    this.ctx.shadowBlur = 15;

    const lightning = this.createLightning();
    this.ctx.beginPath();
    for (const point of lightning) {
      this.ctx.lineTo(point.x, point.y);
    }
    this.ctx.stroke();

    if (this.active) {
      requestAnimationFrame(() => this.render());
    }
  }
}
