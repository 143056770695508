class Audio {
  load = null;
  play = null;
  pause = null;
  stop = null;
  switch = null;
  change = null;

  audioContext = null;
  audioContextPendingRequest = {};
  /** @type {{string:AudioBuffer}} */
  audioBuffers = {};
  audioBufferSources = {};
  currentTag = null;
  currentPlayingTag = null;

  /** @type {GainNode} */
  gain = null;

  init() {
    if (!this.audioContext) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.gain = this.audioContext.createGain();
      this.gain.connect(this.audioContext.destination);
    }
  }

  getTagFromURL(url, tag) {
    if (tag != null) return tag;
    return this.getSingleURL(url);
  }

  getSingleURL(urls) {
    if (typeof urls === "string") return urls;
    return urls[0];
  }

  getMultiURL(urls) {
    if (typeof urls === "string") return [urls];
    return urls;
  }

  setVolume(volume) {
    if (!Number.isFinite(volume) || Number.isNaN(volume)) {
      return;
    }
    this.gain.gain.value = Math.max(Math.min(volume, 1), 0);
  }

  loadSoundAudioContext(urls, tag) {
    let url = this.getSingleURL(urls);
    tag = this.getTagFromURL(urls, tag);
    let req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = 'arraybuffer';
    req.onload = () => {
      let retVal = this.audioContext.decodeAudioData(req.response, onLoadSuccessful, onLoadFailed);
      if (retVal && typeof retVal.then === 'function') {
        retVal.then(onLoadSuccessful).catch((e) => {
          onLoadFailed(e);
          urls.shift(); // remove the first url
          if (urls.length > 0) {
            this.loadSoundAudioContext(urls, tag); // try the next url
          }
        });
      }
    };
    req.send();

    const onLoadSuccessful = (buffer) => {
      //buffer.connect(this.gain);

      this.audioBuffers[tag] = buffer;
      if (this.audioContextPendingRequest[tag]) {
        this.playSoundAudioContext(tag);
      }
    }

    const onLoadFailed = (e) => {
      console.error("Error loading audio", e);
    }
  }

  playSoundAudioContext(tag) {
    let context = this.audioContext;

    if (tag == null) {
      tag = this.currentTag;
    }

    if (context == null) return;

    let buffer = this.audioBuffers[tag];
    if (buffer == null) {
      this.audioContextPendingRequest[tag] = true;
      return;
    }

    /** @type {AudioBufferSourceNode} */
    let source = context.createBufferSource(); // creates a sound source
    source.buffer = buffer; // tell the source which sound to play
    source.connect(this.gain);
    source.loop = false;

    if (this.currentPlayingTag === tag) {
      // Stop the previous source if the same sound is played again
      let previousSource = this.audioBufferSources[tag];
      if (previousSource) {
        if (typeof previousSource.noteOff === 'function') {
          previousSource.noteOff(0);
        } else {
          previousSource.stop();
        }
      }
    }

    this.audioBufferSources[tag] = source;

    if (typeof source.noteOn === "function") {
      source.noteOn(0);
    } else {
      source.start();
    }

    this.currentTag = tag;
    this.currentPlayingTag = tag;

    if (context.state === 'suspended') {
      // if the audio context is in a suspended state then unpause (resume)
      context.resume().then(function () {
      }).catch(function (e) {
        console.error("Failed to resume audio", tag, e);
      });
    } else if (context.state === 'closed') {
      console.error("Audio context is closed", tag);
    } else if (context.state !== 'running') {
      console.error("Failed to play audio context, unknown state", tag, context.state);
    }
  }

  pauseSoundAudioContext() {
    // not passing in a "tag" parameter because we are playing all audio in one channel
    let tag = this.currentPlayingTag;
    let context = this.audioContext;

    if (tag === undefined) {
      // ignore request to pause sound as nothing is currently playing
      return;
    }

    // find currently playing (running) audio and pause it (suspend)
    if (context !== undefined) {
      // ref: https://developer.mozilla.org/en-US/docs/Web/API/AudioContext/suspend
      if (context.state === 'running') {

        context.suspend().then(function () {

        }).catch(function (e) {
          console.error("pauseSoundAudioContext suspend error for " + tag + ". Error: ", e);
        });
      } else if (context.state === 'suspended') {
        // ignore request to pause sound - it's already suspended
      } else if (context.state === 'closed') {
        // ignore request to pause sound - it's already closed
      } else {
        console.warn("pauseSoundAudioContext unknown AudioContext.state for " + tag + ". State: " + context.state);
      }
    }
  }

  stopSoundAudioContext() {
    // not passing in a "tag" parameter because we are playing all audio in one channel
    const tag = this.currentPlayingTag;

    if (tag === undefined) {
      // ignore request to stop sound as nothing is currently playing
      return;
    } else {
    }

    // find current playing audio and stop it
    const source = this.audioBufferSources[tag];
    if (source !== undefined) {
      if (typeof (source.noteOff) == "function") {
        source.noteOff(0);
      } else {
        source.stop();
      }
      this.audioBufferSources[tag] = undefined;
      this.currentPlayingTag = undefined;
    }
  }

  switchSoundAudioContext(autoplay) {
    if (this.currentTag && lowLag.currentTag === 'audio1') {
      this.currentTag = 'audio2';
    } else {
      this.currentTag = 'audio1';
    }

    if (autoplay) {
      this.playSoundAudioContext();
    }
  }

  changeSoundAudioContext(tag, autoplay) {
    if (tag === undefined) {
      return;
    }

    this.currentTag = tag;

    if (autoplay) {
      this.playSoundAudioContext();
    }
  }
}

export {Audio};