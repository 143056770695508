import {postEvent} from "@tma.js/sdk";

/**
 * The HapticsDriver class is responsible for managing the haptic feedback functionality
 * on supported platforms using the Telegram WebApp API.
 */
export class HapticsDriver {
  init() {
    const isEnabledValue = window.localStorage.getItem('hapticsDriver.isEnabled');
    if (isEnabledValue) {
      this.#isEnabled = JSON.parse(isEnabledValue);
    }
  }

  /**
   * Triggers haptic feedback on supported platforms (iOS and Android) through the Telegram WebApp API.
   *
   * This method checks if the Telegram WebApp API is available in the global window context,
   * and if so, sends a request to trigger light impact haptic feedback based on the platform.
   */
  playHaptic() {
    if (!window.hasOwnProperty('Telegram')) {
      return;
    }

    if (!window.Telegram.hasOwnProperty('WebApp')) {
      return;
    }

    if (!this.#isEnabled) {
      return;
    }

    switch (window.Telegram.WebApp.platform) {
      case 'android':
        postEvent('web_app_trigger_haptic_feedback', {type: 'impact', impact_style: 'light'});
        break;
      case 'ios':
        postEvent('web_app_trigger_haptic_feedback', {type: 'impact', impact_style: 'light'});
        break;
      default:
        //console.log("Haptic feedback is not supported on this platform!");
        break;
    }
  }

  /**
   * Toggles the haptic feedback setting between enabled and disabled states.
   */
  toggleHaptics() {
    this.#isEnabled = !this.#isEnabled;
    if (this.#isEnabled) {
      console.log("HAPTICS ON");
    } else {
      console.log("HAPTICS OFF");
    }

    // Update persistent setting.
    window.localStorage.setItem('hapticsDriver.isEnabled', JSON.stringify(this.#isEnabled));
  }

  isEnabled() {
    return this.#isEnabled;
  }

  // --- Properties ---

  #isEnabled = true;
}
