export class I18nDriver {
  async init(backendDriver) {
    this.#backendDriver = backendDriver;

    let tgLanguage = null;
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.user && window.Telegram.WebApp.initData.user.languageCode) {
      tgLanguage = window.Telegram.WebApp.initData.user.languageCode;
    }
    const navigatorLanguage = navigator.language.slice(0, 2);
    const language = tgLanguage || navigatorLanguage || 'ru';

    await this.loadTranslations(language);
  }

  /** @returns {Promise<string>} */
  async translate(key) {
    if (!this.#translationsLoadingPromise) {
      return new Promise((resolve) => resolve(this.#translations[key] || this.#translationsFallback[key] || key));
    } else {
      await this.#translationsLoadingPromise;
      return this.#translations[key] || this.#translationsFallback[key] || key;
    }
  }

  async loadTranslations(language) {
    if (!this.#backendDriver) {
      console.error('Backend driver is not initialized');
      return Promise.reject('Backend driver is not initialized');
    }

    const url = `${this.#backendDriver.baseUrl}/translations.json`;
    this.#translationsLoadingPromise = new Promise(async (resolve, reject) => {
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.#translations = data[language];
          this.#translationsFallback = data['en'];
          this.applyTranslations(this.#translations);
          resolve();
        })
        .catch(error => {
          console.error(`Failed to load translations from ${url}`, error);
          reject(error);
        });
    });
  }

  applyTranslations(translations) {
    const elements = document.querySelectorAll('[data-i18n]');
    for (let i = 0; i < elements.length; ++i) {
      const element = elements[i];
      const key = element.getAttribute('data-i18n');
      element.textContent = translations[key] || this.#translationsFallback[key] || key;
    }
  }

  async applyTranslationsToElement(element) {
    if (!element) {
      return;
    }

    if (!this.#translationsLoadingPromise) {
      return;
    }

    await this.#translationsLoadingPromise;

    const elements = element.querySelectorAll('[data-i18n]');
    for (let i = 0; i < elements.length; ++i) {
      const element = elements[i];
      const key = element.getAttribute('data-i18n');
      element.textContent = this.#translations[key] || this.#translationsFallback[key] || key;
    }
  }

  /**
   * @type {BackendDriver}
   */
  #backendDriver = null;

  /**
   * @type {Object.<string, string>}
   */
  #translations = {};

  /**
   * @type {Object.<string, string>}
   */
  #translationsFallback = {};

  /** @type {Promise<*>} */
  #translationsLoadingPromise = null;
}