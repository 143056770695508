import axios from "axios";
import * as Sentry from "@sentry/browser";

export const MessageTypes = {
  SPINS: 0,
  ENERGY: 1,
  FRIENDS: 2,
  FRIENDS_CODE: 3,
  CONSUMABLES: 4,
  UPGRADES: 5,
  SPIN_MULTIPLIER: 6,
  PLAYER_DATA: 9,
  START_ROTATING: 10,
  STOP_ROTATING: 11,
  TOGGLE_SKIN: 12,

}

/** Manages REST and WebSocket communication with the backend. */
export class BackendDriver {
  /** @type {WebSocket} */
  #ws = null;
  #webSocketUrl = '';

  //region REST
  #webSocketProtocol = '';
  baseUrl = '';
  #initDataTest = btoa('user=%7B%22id%22%3A5077488730%2C%22first_name%22%3A%22Heinhain%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22He1nhain%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=5875186820166919882&chat_type=private&start_param=WjVjpsX6FW&auth_date=1729003725&hash=af482936900e315fff0a6c765cf69fd127952b0fa1d9283e1256d84e08e9cd1f');
  #initData = '';

  async init() {
    this.initEnvironment();

    // Initialize the WebSocket connection.
    await this.initWebsocket();

    // Graceful shutdown the WebSocket connection before the window closes.
    window.addEventListener('beforeunload', this.closeWebSocket.bind(this));
  }

  initEnvironment() {
    if (process.env.NODE_ENV !== 'development') {
      this.baseUrl = 'https://spinner.fi.hackerman.me';
      // Get the Telegram init data.
      if (window.Telegram && window.Telegram.WebApp) {
        const initData = window.Telegram.WebApp.initData;
        this.#initData = btoa(initData);
      }
    } else {
      // todo: Development environment. Remove this block when deploying to production.
      this.baseUrl = 'http://localhost:8080';
      this.#initData = btoa(this.#initDataTest);
    }
    this.#webSocketUrl = this.baseUrl.replace('http://', '').replace('https://', '');
    this.#webSocketProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  }

  async getLeaderboard() {
    try {
      const res = await axios.get(this.baseUrl.concat('/api/leaderboard'), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (e) {
      console.error(e);
      return {leaderboard: []};
    }
  }

  async getFriends() {
    try {
      const res = await axios.get(this.baseUrl.concat('/api/friends'), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (e) {
      console.error(e);
      return {friends: [], totalFriends: 0, totalRewards: 0, inviteCode: ""};
    }
  }

  async getCode() {
    try {
      const res = await axios.get(this.baseUrl.concat('/api/friends/invite-code'), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });

      if (res.status === 200 && res.data.inviteCode) {
        localStorage.setItem('referralCode', res.data.inviteCode);
        return res.data.inviteCode;
      } else {
        return '';
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getQuests() {
    try {
      const res = await axios.get(this.baseUrl.concat('/api/quests'), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.#initData
        }
      });
      if (res.status === 200) {
		  return new Map(Object.entries(res.data['quests']));
      }
    } catch (e) {
      console.error(e);
      return {quests: []};
    }
  }

  //endregion

  //region WebSocket

  async finishQuest(questId) {
  	try {
		const url = this.baseUrl.concat('/api/quests/finish');
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': this.#initData,
			'QuestId': questId
		};
		const res = await axios.post(url, {}, {headers});
		return res.data;
	  }
	  catch (e) {
		console.error(e);
		return {quests: []};
	  }
  }

	async getAllItems() {
		try {
			const res = await axios.get(this.baseUrl.concat('/api/items'), {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': this.#initData
				}
			});
			if (res.status === 200) {
				return new Map(Object.entries(res.data['items']));
			}
		} catch (e) {
			console.error(e);
			return {};
		}
	}

	async getPlayerItems() {
		try {
			const res = await axios.get(this.baseUrl.concat('/api/items/player'), {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': this.#initData
				}
			});
			if (res.status === 200) {
				return new Map(Object.entries(res.data['items']));
			}
		} catch (e) {
			console.error(e);
			return {};
		}
	}

  async useDailyBoost(boostType) {
    // todo: Implement
    try {
      console.log('useDailyBoost');
      const url = this.baseUrl.concat('/api/consumables/activate');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': this.#initData,
        'Type': boostType
      };
      const res = await axios.post(url, {}, {headers});
      console.log(res.data);

    } catch (e) {
      console.error(e);
    }
  }

  async buyUpgrade(itemType) {
    try {
      console.log('buyUpgrade');
      const url = this.baseUrl.concat('/api/upgrades/buy');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': this.#initData,
        'Type': itemType
      };
      const res = await axios.post(url, {}, {headers});
	  this.showMessage(true, itemType, res.data.message);
    } catch (e) {
	  this.showMessage(false, itemType, e.response.data.error);

    }
  }

  async checkGroupMembership() {


  }

  /** Initializes the WebSocket connection. */
  async initWebsocket() {
    try {
      this.#webSocketProtocol.concat(this.#webSocketUrl.concat('/ws?initData=').concat(this.#initData));
      this.#ws = new WebSocket(this.#webSocketProtocol.concat(this.#webSocketUrl.concat('/ws?initData=').concat(this.#initData)));

      this.#ws.onopen = () => {
      };

      this.#ws.onmessage = (message) => {
        this.receiveData(message);
      };

      this.#ws.onerror = (error) => {
        console.error('Socket encountered error: ', error.message, 'Closing socket', JSON.stringify(error));
        this.#ws.close();
      }

      this.#ws.onclose = (event) => {
        if (event.wasClean) {
          console.log('Connection closed cleanly');
        } else {
          console.log('Connection abruptly closed');
        }

        // 1005 is the code for a clean close.
        if (event.code !== 1005) {
          console.log('Code: ' + event.code + ' Reason: ' + event.reason);

          Sentry.captureMessage('WebSocket connection closed', 'error');

          setTimeout(this.initWebsocket.bind(this), 1000);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Send data to websocket
   * @param {string} data -- JSON data to send formatted as string.
   * Should be formatted:
   * {
   *    "t": message type defined in MessageTypes,
   *    "d": JSON.stringify(data)
   * }
   */
  async sendData(data) {
    try {
      if (this.#ws && this.#ws.readyState === WebSocket.OPEN) {
        this.#ws.send(data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Process any data, received from websocket
   * @param message -- message received from websocket
   */
  receiveData(message) {
    if (this.receiveDataCallback) {
      this.receiveDataCallback(message);
    }
  }

  showMessage(isSuccess, itemType, message) {
	if (this.showMessageCallback) {
	  this.showMessageCallback(isSuccess, itemType, message);
	}
  }

  //endregion

  setMessagesCallback(callback) {
	this.showMessageCallback = callback;
  }

  /**
   * Sets the callback function to handle received data.
   * @param {Function} callback - The callback function.
   */
  setReceiveDataCallback(callback) {
    this.receiveDataCallback = callback;
  }

  /** Closes the WebSocket connection. */
  closeWebSocket() {
    if (this.#ws) {
      this.#ws.close();
    }
  }
}
