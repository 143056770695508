import {Audio} from "./audio.js";

/**
 * The AudioDriver class is responsible for handling audio functionality,
 * including initialization, sound playback, and volume control.
 */
export class AudioDriver {
  /**
   * Initializes the audio driver by setting up the click and spinner sounds.
   * This involves initializing the audio contexts, loading the sound files,
   * setting changes to the sound context, and adjusting the initial volume.
   */
  init() {
    // Load the audio settings from local storage.
    const isEnabledValue = window.localStorage.getItem('audioDriver.isEnabled');
    if (isEnabledValue) {
      this.#isEnabled = JSON.parse(isEnabledValue);
    }

    // Score click sound.
    if (this.#clickAudio) {
      this.#clickAudio.init();
      this.#clickAudio.loadSoundAudioContext(['/click.mp3'], 'click');
      this.#clickAudio.changeSoundAudioContext('click', false);
    }

    // Spinner bearing sound.
    if (this.#bearingAudio) {
      this.#bearingAudio.init();
      this.#bearingAudio.loadSoundAudioContext(['/spinner.mp3'], 'spinner');
      this.#bearingAudio.changeSoundAudioContext('spinner', false);
    }

    this.#updateAudioState();
  }

  /**
   * Plays a spinner click sound if sound is enabled in the window settings.
   */
  playClickSound() {
    if (this.#isEnabled && this.#clickAudio) {
      this.#clickAudio.playSoundAudioContext('click');
    }
  }

  /**
   * Plays the spinner bearing sound if the sound setting is enabled.
   */
  playBearingSound() {
    if (this.#isEnabled && this.#bearingAudio) {
      this.#bearingAudio.playSoundAudioContext('spinner');
    }
  }

  /**
   * Stops the bearing sound if it is currently playing.
   */
  stopBearingSound() {
    if (this.#bearingAudio) {
      this.#bearingAudio.stopSoundAudioContext();
    }
  }

  /**
   * Updates the audio volume based on enable state.
   */
  #updateAudioState() {
    const volume = this.#isEnabled ? 1 : 0;

    if (this.#bearingAudio) {
      this.#bearingAudio.gain.gain.value = this.#isEnabled ? this.#spinnerVolume : volume;
    }

    if (this.#clickAudio) {
      this.#clickAudio.gain.gain.value = this.#isEnabled ? this.#clickVolume : volume;
    }
  }

  /**
   * Toggles the sound state between enabled and disabled.
   */
  toggleAudio() {
    this.#isEnabled = !this.#isEnabled;

    this.#updateAudioState();

    // Save the audio settings to local storage.
    window.localStorage.setItem('audioDriver.isEnabled', JSON.stringify(this.#isEnabled));
  }

  /**
   * Returns whether the audio is enabled.
   * @return {boolean}
   */
  isEnabled() {
    return this.#isEnabled;
  }

  /**
   * Updates the volume of the spinner audio based on the specified angular velocity.
   *
   * @param {number} angularVelocity - The current angular velocity of the spinner.
   * A higher velocity increases the volume.
   */
  updateSpinnerAudioVolume(angularVelocity) {
    if (!this.#isEnabled || !this.#bearingAudio) {
      return;
    }

    // Compute the volume based on the angular velocity and set it.
    const volume = this.#getVolumeFromAngularVelocity(angularVelocity, this.#maxAngularVelocity);
    this.#bearingAudio.setVolume(volume);
  }

  /**
   * Computes the volume level as a proportion of the angular velocity.
   *
   * @param {number} angularVelocity - The angular velocity to compute the volume from.
   * @param {number} [maxAngularVelocity=5] - The maximum angular velocity for normalization.
   * @return {number} A normalized volume level between 0 and 1.
   */
  #getVolumeFromAngularVelocity(angularVelocity, maxAngularVelocity) {
    return Math.min(Math.abs(angularVelocity) / maxAngularVelocity, 1);
  }

  //region Properties

  /**
   * Represents the maximum angular velocity used for bearing volume normalization.
   */
  #maxAngularVelocity = 0.0333;

  /**
   * @description A flag indicating whether a feature or functionality is enabled.
   * @type {boolean}
   */
  #isEnabled = true;

  /**
   * Represents an audio object for click sounds.
   * This Audio object is intended to be used for playing
   * a sound effect when the spinner goes round a full circle indicating the reward.
   * The audio file should be set before invoking any playback methods.
   * @type {Audio}
   */
  #clickAudio = new Audio();

  /**
   * Represents an audio object for spinner rotation sound.
   * The audio file should be set before invoking any playback methods.
   * @type {Audio}
   */
  #bearingAudio = new Audio();

  /**
   * Volume level of the click sound.
   * @type {number}
   */
  #clickVolume = 0.35;

  /**
   * Volume level of the spinner audio.
   * @type {number}
   */
  #spinnerVolume = 1;

  //endregion
}
