import "bootstrap-icons/font/bootstrap-icons.css";
import './style.css';
import {AudioDriver} from "./audio-driver.js";
import {BackendDriver} from './backend-driver.js';
import {DeviceOrientationDriver} from './device-orientation-driver.js';
import {HapticsDriver} from './haptics-driver.js';
import {GameUI} from './game-ui.js';
import {GameMode} from './game-mode.js';
import {I18nDriver} from "./i18n-driver.js";
import {FxDriver} from "./fx-driver.js";
import {SentryClient} from "./sentry-driver.js";

window.Telegram = window.Telegram || {
  WebApp: {
    platform: null,
    initDataUnsafe: {
      user: {
        languageCode: '',
        first_name: '',
        last_name: '',
      }
    },
    disableVerticalSwipes: () => {
    }
  }
};
window.process = window.process || {env: {NODE_ENV: 'production'}};

window.addEventListener("DOMContentLoaded", async () => {

  document.getElementById('debug').textContent = "0.0.170";

  // --- Initialization ---

  const sentryDriver = SentryClient;

  const backendDriver = new BackendDriver();
  await backendDriver.init();

  const i18nDriver = new I18nDriver();
  await i18nDriver.init(backendDriver);

  const fxDriver = new FxDriver();
  fxDriver.init();

  const audioDriver = new AudioDriver();
  audioDriver.init();

  const hapticsDriver = new HapticsDriver();
  hapticsDriver.init();

  const deviceOrientationDriver = new DeviceOrientationDriver();
  deviceOrientationDriver.init();

  const gameUI = new GameUI();
  await gameUI.init(sentryDriver, audioDriver, hapticsDriver, backendDriver, deviceOrientationDriver, i18nDriver, fxDriver);

  const gameMode = new GameMode();
  gameMode.init(gameUI, audioDriver, hapticsDriver, backendDriver, fxDriver);

  if (window.Telegram && window.Telegram.WebApp) {
    // Allow only mobile devices.
    if (typeof window.Telegram.WebApp.platform === "string") {
      switch (window.Telegram.WebApp.platform) {
        case 'android':
        case 'ios':
          break;
        default:
          // Replace body contents with a message.
          // document.body.innerHTML = `<p class="mobile-only">Spinner is available only for mobile devices.</p>`;
          break;
      }
    }
  } else {
    // Replace body contents with a message.
    // document.body.innerHTML = `<p class="mobile-only">Spinner is available only for mobile devices.</p>`;
  }
});
