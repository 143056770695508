import * as Sentry from "@sentry/browser";

export const SentryClient = Sentry.init({
  dsn: "https://b69e3ec806e2c0ae3c92a3d43fc90f57@o4508334987149312.ingest.de.sentry.io/4508334990688336",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: "0.0.173",
  integrations: [
    Sentry.browserTracingIntegration({
      // disable automatic span creation
      instrumentNavigation: false,
      instrumentPageLoad: false,
    }),
    Sentry.replayIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.captureConsoleIntegration(),
    Sentry.globalHandlersIntegration(),
    Sentry.extraErrorDataIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^https:\/\/spinner\.fi\.hackerman\.me/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (window.Telegram && window.Telegram.WebApp) {
  const initData = window.Telegram.WebApp.initDataUnsafe;
  if (initData && initData.user) {
    Sentry.setUser({
      id: initData.user.id,
      username: initData.user.username,
      firstName: initData.user.first_name,
      lastName: initData.user.last_name
    })
  }
}
